var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type == 1 && _vm.information)?_c('div',[_c('el-descriptions',{attrs:{"column":1,"label-style":{
				color: '#909399',
				width: '100px',
				paddingLeft: '40px',
				fontsize: '14px',
			},"contentStyle":{ fontsize: '14px', color: '#303133' }}},[_c('el-descriptions-item',{attrs:{"label":"报修类型"}},[_vm._v(_vm._s(_vm.information.repairTypeName || "-"))]),_c('el-descriptions-item',{attrs:{"label":"用户类型"}},[_vm._v(" "+_vm._s(_vm.getDictLabel( _vm.residentList, _vm.information.residentType, "id", "name", "-" )))]),_c('el-descriptions-item',{attrs:{"label":"关联用户"}},[_vm._v(_vm._s(_vm.information.residentName || "-"))]),_c('el-descriptions-item',{attrs:{"label":"联系人"}},[_vm._v(_vm._s(_vm.information.contactName || "-"))]),_c('el-descriptions-item',{attrs:{"label":"联系电话"}},[_vm._v(_vm._s(_vm.information.contactMobile || "-"))]),_c('el-descriptions-item',{attrs:{"label":"备用联系电话"}},[_vm._v(_vm._s(_vm.information.contactMobileEx || "-"))]),_c('el-descriptions-item',{attrs:{"label":"报修地址"}},[_vm._v(_vm._s(_vm.information.address || "-"))]),_c('el-descriptions-item',{attrs:{"label":"备注"}},[_vm._v(_vm._s(_vm.information.repRemark || "-"))]),_c('el-descriptions-item',{attrs:{"label":"优先级"}},[_vm._v(" "+_vm._s(_vm.getDictLabel( _vm.priorityList, _vm.information.priorLevel, "id", "priorLevel", "-" ))+" ")]),_c('el-descriptions-item',{attrs:{"label":"来源"}},[_vm._v(" "+_vm._s(_vm.getDictLabel( _vm.sourcList, _vm.information.source, "id", "name", "-" ))+" ")])],1)],1):_vm._e(),(_vm.type == 2 && _vm.information)?_c('div',[_c('div',{staticClass:"message"},[_c('div',{staticClass:"detil-code"},[_vm._v("报修信息")]),_c('el-descriptions',{attrs:{"column":1,"label-style":{
					color: '#909399',
					width: '100px',
					paddingLeft: '40px',
					fontsize: '14px',
				},"contentStyle":{ fontsize: '14px', color: '#303133' }}},[_c('el-descriptions-item',{attrs:{"label":"户主姓名"}},[_vm._v(_vm._s(_vm.information.residentName || "-"))]),_c('el-descriptions-item',{attrs:{"label":"户主电话"}},[_vm._v(_vm._s(_vm.information.mobile || "-"))]),_c('el-descriptions-item',{attrs:{"label":"身份证号"}},[_vm._v(_vm._s(_vm.information.idnumber || "-"))]),_c('el-descriptions-item',{attrs:{"label":"用户分类"}},[_vm._v(_vm._s(_vm.information.userTypeName || "-"))]),_c('el-descriptions-item',{attrs:{"label":"片区"}},[(!_vm.information.addrName)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(" "+_vm._s(_vm.information.addrName)+" "),(_vm.information.addrsName)?_c('span',[_vm._v("-"+_vm._s(_vm.information.addrsName))]):_vm._e()])]),_c('el-descriptions-item',{attrs:{"label":"地址"}},[_vm._v(_vm._s(_vm.information.address || "-"))])],1)],1),_c('div',{staticClass:"message"},[_c('div',{staticClass:"detil-code"},[_vm._v("安检信息")]),_c('el-descriptions',{attrs:{"column":1,"label-style":{
					color: '#909399',
					width: '100px',
					paddingLeft: '40px',
					fontsize: '14px',
				},"contentStyle":{ fontsize: '14px', color: '#303133' }}},[_c('el-descriptions-item',{attrs:{"label":"安检联系人"}},[_vm._v(_vm._s(_vm.information.contactName || "-"))]),_c('el-descriptions-item',{attrs:{"label":"联系电话"}},[_vm._v(_vm._s(_vm.information.contactMobile || "-"))]),_c('el-descriptions-item',{attrs:{"label":"是否为租户"}},[(
							_vm.information.islessee == null &&
							_vm.information.islessee !== 0
						)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(_vm.getDictLabel( _vm.islesseeList, _vm.information.islessee, "id", "name", "-" )))])])],1)],1),_c('div',{staticClass:"message"},[_c('div',{staticClass:"detil-code"},[_vm._v("表具信息")]),_c('el-descriptions',{attrs:{"column":1,"label-style":{
					color: '#909399',
					width: '100px',
					paddingLeft: '40px',
					fontsize: '14px',
				},"contentStyle":{ fontsize: '14px', color: '#303133' }}},[_c('el-descriptions-item',{attrs:{"label":"表编号"}},[_vm._v(_vm._s(_vm.information.sheetSteel || "-"))]),_c('el-descriptions-item',{attrs:{"label":"燃气表类型"}},[_vm._v(_vm._s(_vm.information.tableTypeName || "-"))]),_c('el-descriptions-item',{attrs:{"label":"表向"}},[_vm._v(_vm._s(_vm.information.tableDirectionName || "-"))]),_c('el-descriptions-item',{attrs:{"label":"燃气表厂家"}},[_vm._v(_vm._s(_vm.information.tableVenderName || "-"))]),_c('el-descriptions-item',{attrs:{"label":"表封号"}},[_vm._v(_vm._s(_vm.information.tableTitles || "-"))]),_c('el-descriptions-item',{attrs:{"label":"开户日期"}},[_vm._v(_vm._s(_vm.information.openDate || "-"))]),_c('el-descriptions-item',{attrs:{"label":"所接炉具"}},[_vm._v(_vm._s(_vm.information.receiveStove || "-"))]),_c('el-descriptions-item',{attrs:{"label":"备注"}},[_vm._v(_vm._s(_vm.information.tableRemark || "-"))])],1)],1)]):_vm._e(),(_vm.type == 3 && _vm.information)?_c('div',[_c('el-descriptions',{attrs:{"column":1,"label-style":{
				color: '#909399',
				width: '100px',
				paddingLeft: '40px',
				fontsize: '14px',
			},"contentStyle":{ fontsize: '14px', color: '#303133' }}},[_c('el-descriptions-item',{attrs:{"label":"事件类型"}},[_vm._v(_vm._s(_vm.information.eventCodeIdConfirmName || "-"))]),_c('el-descriptions-item',{attrs:{"label":"详细地址"}},[_vm._v(_vm._s(_vm.information.eventAddressConfirm || "-"))]),_c('el-descriptions-item',{attrs:{"label":"说明"}},[_vm._v(_vm._s(_vm.information.eventDescribe || "-"))]),_c('el-descriptions-item',{attrs:{"label":"图片"}})],1),(_vm.information.imgUrl)?_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","padding-left":"35px"}},_vm._l((_vm.information.imgUrl.split(',')),function(url,index){return _c('el-image',{key:index,staticStyle:{"width":"100px","height":"100px","border":"1px solid #dddddd","margin":"5px"},attrs:{"src":url,"preview-src-list":_vm.information.imgUrl.split(','),"z-index":index}})}),1):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }