<template>
	<div>
		<div v-if="type == 1 && information">
			<el-descriptions
				:column="1"
				:label-style="{
					color: '#909399',
					width: '100px',
					paddingLeft: '40px',
					fontsize: '14px',
				}"
				:contentStyle="{ fontsize: '14px', color: '#303133' }"
			>
				<el-descriptions-item label="报修类型">{{
					information.repairTypeName || "-"
				}}</el-descriptions-item>
				<el-descriptions-item label="用户类型">
					{{
						getDictLabel(
							residentList,
							information.residentType,
							"id",
							"name",
							"-"
						)
					}}</el-descriptions-item
				>
				<el-descriptions-item label="关联用户">{{
					information.residentName || "-"
				}}</el-descriptions-item>
				<el-descriptions-item label="联系人">{{
					information.contactName || "-"
				}}</el-descriptions-item>
				<el-descriptions-item label="联系电话">{{
					information.contactMobile || "-"
				}}</el-descriptions-item>
				<el-descriptions-item label="备用联系电话">{{
					information.contactMobileEx || "-"
				}}</el-descriptions-item>
				<el-descriptions-item label="报修地址">{{
					information.address || "-"
				}}</el-descriptions-item>
				<el-descriptions-item label="备注">{{
					information.repRemark || "-"
				}}</el-descriptions-item>
				<el-descriptions-item label="优先级">
					{{
						getDictLabel(
							priorityList,
							information.priorLevel,
							"id",
							"priorLevel",
							"-"
						)
					}}
				</el-descriptions-item>
				<el-descriptions-item label="来源">
					{{
						getDictLabel(
							sourcList,
							information.source,
							"id",
							"name",
							"-"
						)
					}}
				</el-descriptions-item>
			</el-descriptions>
		</div>
		<div v-if="type == 2 && information">
			<div class="message">
				<div class="detil-code">报修信息</div>
				<el-descriptions
					:column="1"
					:label-style="{
						color: '#909399',
						width: '100px',
						paddingLeft: '40px',
						fontsize: '14px',
					}"
					:contentStyle="{ fontsize: '14px', color: '#303133' }"
				>
					<el-descriptions-item label="户主姓名">{{
						information.residentName || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="户主电话">{{
						information.mobile || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="身份证号">{{
						information.idnumber || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="用户分类">{{
						information.userTypeName || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="片区">
						<span v-if="!information.addrName">-</span>
						<span v-else>
							{{ information.addrName }}
							<span v-if="information.addrsName"
								>-{{ information.addrsName }}</span
							>
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="地址">{{
						information.address || "-"
					}}</el-descriptions-item>
				</el-descriptions>
			</div>
			<div class="message">
				<div class="detil-code">安检信息</div>
				<el-descriptions
					:column="1"
					:label-style="{
						color: '#909399',
						width: '100px',
						paddingLeft: '40px',
						fontsize: '14px',
					}"
					:contentStyle="{ fontsize: '14px', color: '#303133' }"
				>
					<el-descriptions-item label="安检联系人">{{
						information.contactName || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="联系电话">{{
						information.contactMobile || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="是否为租户">
						<span
							v-if="
								information.islessee == null &&
								information.islessee !== 0
							"
							>-</span
						>
						<span v-else>{{
							getDictLabel(
								islesseeList,
								information.islessee,
								"id",
								"name",
								"-"
							)
						}}</span></el-descriptions-item
					>
				</el-descriptions>
			</div>
			<div class="message">
				<div class="detil-code">表具信息</div>
				<el-descriptions
					:column="1"
					:label-style="{
						color: '#909399',
						width: '100px',
						paddingLeft: '40px',
						fontsize: '14px',
					}"
					:contentStyle="{ fontsize: '14px', color: '#303133' }"
				>
					<el-descriptions-item label="表编号">{{
						information.sheetSteel || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="燃气表类型">{{
						information.tableTypeName || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="表向">{{
						information.tableDirectionName || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="燃气表厂家">{{
						information.tableVenderName || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="表封号">{{
						information.tableTitles || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="开户日期">{{
						information.openDate || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="所接炉具">{{
						information.receiveStove || "-"
					}}</el-descriptions-item>
					<el-descriptions-item label="备注">{{
						information.tableRemark || "-"
					}}</el-descriptions-item>
				</el-descriptions>
			</div>
		</div>
		<div v-if="type == 3 && information">
			<el-descriptions
				:column="1"
				:label-style="{
					color: '#909399',
					width: '100px',
					paddingLeft: '40px',
					fontsize: '14px',
				}"
				:contentStyle="{ fontsize: '14px', color: '#303133' }"
			>
				<el-descriptions-item label="事件类型">{{
					information.eventCodeIdConfirmName || "-"
				}}</el-descriptions-item>

				<el-descriptions-item label="详细地址">{{
					information.eventAddressConfirm || "-"
				}}</el-descriptions-item>
				<el-descriptions-item label="说明">{{
					information.eventDescribe || "-"
				}}</el-descriptions-item>
				<el-descriptions-item label="图片"> </el-descriptions-item>
			</el-descriptions>
			<div
				style="display: flex; flex-wrap: wrap;padding-left: 35px;"
				v-if="information.imgUrl"
			>
				<el-image
					v-for="(url, index) in information.imgUrl.split(',')"
					:key="index"
					style="
						width: 100px;
						height: 100px;
						border: 1px solid #dddddd;
						margin: 5px;
					"
					:src="url"
					:preview-src-list="information.imgUrl.split(',')"
					:z-index="index"
				>
				</el-image>
			</div>
		</div>
	</div>
</template>

<script>
	import { getDictLabel } from "@/utils/common";
	import { getTimeoutList } from "@/RequestPort/maintenance";
	export default {
		name: "News",
		props: {
			information: {
				type: Object,
				default: () => {},
			},
			type: {
				type: [String, Number],
				default: "1",
			},
		},
		data() {
			return {
				getDictLabel,
				residentList: [
					{
						id: 1,
						name: "居民",
					},
					{
						id: 2,
						name: "非居民",
					},
				],
				islesseeList: [
					{
						id: 0,
						name: "否",
					},
					{
						id: 1,
						name: "是",
					},
				],
				sourcList: [
					{
						id: 1,
						name: "电话",
					},
					{
						id: 2,
						name: "大厅",
					},
					{
						id: 3,
						name: "维修员",
					},
				],
				priorityList: [],
			};
		},
		mounted() {
			this.getInfo();
		},
		methods: {
			getInfo() {
				getTimeoutList().then((res) => {
					this.priorityList = res.data;
				});
			},
		},
	};
</script>

<style scoped>
	.message {
		margin-left: 10px;
	}
	.detil-code {
		margin-left: 30px;
		/* padding-left: 9px; */
		font-size: 14px;
		height: 14px;
		line-height: 14px;
		color: #000000;
		position: relative;
		margin-bottom: 20px;
		font-weight: bolder;
	}
	/* .detil-code:before {
			content: "";
			position: absolute;
			width: 3px;
			height: 14px;
			background-color: #007aff;
			top: 0;
			left: 0;
		} */
</style>